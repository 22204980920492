export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://nawjzkxfmb.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://hnyx9dp42g.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://5r3j9wjea3.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://vrausyvj91.execute-api.eu-west-1.amazonaws.com/prod',
};
